<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/algo-picslogo.svg" />
    <HelloWorld msg="Welcome to your personalized home decoration maker!" />
  </div>
</template>

<script>

import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>
